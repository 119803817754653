.avatar-bar {
	padding-top: 1em;
	padding-bottom: 1em;
	background-color: #fff;
}

.avatar {
	background-color: #eee;
	width: 60px;
	height: 60px;
	background-size: cover;
	background-position: top center;
	border-radius: 50%;
	display: inline-block;
	vertical-align: middle;	
}

.avatar-text {
	margin-left: 10px;
	display: inline-block;
	vertical-align: middle;	
}


.card {
	padding: 30px 40px;
	border-radius: 6px;
	background-color: #fff;
	-webkit-box-shadow: 0px 7px 6px 3px rgba(142, 143, 144, 0.11);
	-moz-box-shadow:    0px 7px 6px 3px rgba(142, 143, 144, 0.11);
	box-shadow:         0px 7px 6px 3px rgba(142, 143, 144, 0.11);

	& h3 {
		margin-top: 0.5em;
		margin-bottom: 1em;
	}
}

.card-squaredtopleft {
	border-top-left-radius: 0;
}

.content {
	h1 {
		margin-bottom: 0.8em;
	}

	img {
		margin-bottom: 10px;
		margin-top: 10px;
		border-radius: 4px;
	}

	p {
		margin: 0 0 1.2em;
	}
}


.stats-number {
	background-color: #f5f6f8;
	font-size: 100px;
	padding: 30px 50px ;
}





