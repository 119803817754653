.nav-primary {
	padding-top: 1em;
	padding-bottom: 1.5em;
	background-color: #fff;

	.btns-nav {
		margin-top: 1em;
	}
	.btn {
		margin-left: 5px;
		margin-right: 5px;
		min-width: 160px;
	}
}

.nav-secondary {
	background-color: $brand-secondary;
	padding-top: 1em;
	padding-bottom: 0;

	h1 {
		color: #fff;
		font-weight: 300;
	}

}


.nav-tabs {
	margin-top: 70px;
	font-size: 14px;
    border-bottom: 1px solid transparent;

	& li {
		padding-right: 2px;
	}

	&>li>a, &>li>a:focus, &>li>a:hover {
		background-color: #43bcdf;
		color: #fff;
		border: 1px solid transparent;
	}
	
	&>li.active>a, &>li.active>a:hover, &>li.active>a:focus {
		border: 1px solid #fff;
		background-color: #fff;
		color: #444;
	}

}




.nav-color-tertiary {
	background-color: $brand-tertiary;
	.nav-tabs {
		&>li>a, &>li>a:focus, &>li>a:hover {
			background-color: lighten($brand-tertiary, 10%);
		}

		&>li.active>a, &>li.active>a:hover, &>li.active>a:focus {
			background-color: #fff;
		}
	}
}



.nav-tabs-gray {
	&>li>a, &>li>a:focus, &>li>a:hover {
		background-color: $gray-mid;
	}

	&>li.active>a, &>li.active>a:hover, &>li.active>a:focus {
		border: 1px solid #fff;
		background-color: #fff;
	}
}





.pagination {
	&>li>a, &>li>span {
		border: none;
	    padding: 6px 15px;
	    color: $brand-secondary;
	}

	&>.active>a, &>.active>a:focus {
		border-radius: 4px;
		background-color: $brand-secondary;
    	border-color: $brand-secondary;
	}


}







.nav-pills-quicklinks {

	&>li {
		margin-bottom: 12px;
	}

	&>li>a>.fa {
		color: $brand-primary;
		margin-right: 6px;
	}

	&>li>a {
		color: lighten($gray, 10%);
		padding: 15px 20px;
		text-decoration: none;
		background-color: #f5f6f8;
		
		&:after {
			color: lighten($gray, 30%);
			font-family: 'FontAwesome';
			content: '\f054';
			float: right;
			padding-left: 1em;
		}
	}



	&>li>a:focus, &>li>a:hover {
		text-decoration: none;
		background-color: #f2f3f5;
	}	
}


.nav-filters {
	background-color: $gray-mid;

	.btn {
		color: #fff;

		.fa {
			margin-left: 8px;
		}
	}

}







