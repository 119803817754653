// Base Blocks

.block-standard {
	padding-top: $block-padding;
	padding-bottom: $block-padding;	
}


.block-login {
	margin-top: 80px;
	margin-bottom: 80px;

	.card-login {
		margin-left: 30px;
		margin-right: 30px;
	}
	.logo-banner {
		text-align: center;
		margin-bottom: 30px;
	}

	.login-footer {
		text-align: center;
		margin-top: 20px;
	}


	.btn {
		margin-top: 30px;
	}

	label {
		font-weight: normal;
		margin-top: 10px;
	}

	.version-info {
		margin-top: 40px;
		text-align: center;
	}
}



.block-negativeoverlay {
	margin-top: -60px;
}


.block-searchoverlay {
	margin-top: -60px;

	& .card {
		box-shadow: none;
		shadow: none;
		background-color: #009fd0;

		& .form-group {
			margin: 0;
		}
		& .form-control {
			border-radius: 4px;

		}
	}

	& .input-group-btn:last-child>.btn {
 		margin-left: 10px;
 		min-width: 140px;
	
 	}
}


.hero-main {
	height: 420px;
	width: 100%;
	background-color: #eee;
	background-size: cover;
	background-repeat: no-repeat;
}





.bgpos-0-100 { background-position: 0% 100%; }
.bgpos-0-90 { background-position: 0% 90%; }
