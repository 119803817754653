
.form-control {
	background-color: #f5f6f8;
	box-shadow: none;
	padding: 6px 16px;
	border-radius: 5px;
}


.form-control-feedback {
	padding-top: 11px;
	color: #999;
	z-index: 7;
}

.input-group-lg {
	.form-control-feedback {
		font-size: 20px;
		margin-top: -10px;
	}
}

.feedback-left {
	.form-control {
		padding-left: 40px;
	}
	.form-control-feedback {
		left: 0;
	}
}

.form-horizontal .control-label {
	padding-top: 9px;
}