footer.footer {
	padding: 1.2em;
	background-color: #fff;

	// position: absolute;
	bottom: 0;
	width: 100%;
	height: 60px;

	.powered {
		margin-right: 6px;
		font-size: 14px;
	}

	.logo-footer {
		width: 150px;
		margin-top: -4px;
	}
}


footer.footer-alt {
	margin-top: 3em;
	padding-top: 3.5em;
	padding-bottom: 1.5em;
	background-color: #fff;
	font-size: 14px;

	a {
		color: $gray;
	}

	.logo-footer {
		width: 70px;
		margin-top: -24px;
	}


}
