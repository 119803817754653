// Glyphicons font path
$icon-font-path:					"../fonts/";

// Grid settings
$main-sm-columns:					12;
$sidebar-sm-columns:				4;

// Colors
$brand-primary:						#7cb84f;
$brand-secondary:					#009ed0;
$brand-tertiary:					#eb933f;
// $brand-quaternary:					#fbf5f8;
// $brand-quinary:						#f1e8ec;


$brand-success:         #19ae5c;
// $brand-info:            #5bc0de !default;
// $brand-warning:         #f0ad4e !default;
$brand-danger:          #ef2d35;


$gray-dark:							#140e0e;
$gray:								#414141;
$gray-light:						#686868;
$gray-mid:							#9b9b9d;
$gray-lighter:						#d6d6d6;

// Fonts
$font-family-sans-serif:			"proxima-nova", Helvetica, Arial, sans-serif;
$font-family-secondary:				"proxima-nova-soft", Helvetica, Arial, sans-serif;

// $font-family-base:        $font-family-sans-serif !default;

$font-size-base:          16px;
// $font-size-large:         ceil(($font-size-base * 1.25)) !default; // ~18px
// $font-size-small:         ceil(($font-size-base * 0.85)) !default; // ~12px

// $font-size-h1:            floor(($font-size-base * 2.6)) !default; // ~36px
// $font-size-h2:            floor(($font-size-base * 2.15)) !default; // ~30px
// $font-size-h3:            ceil(($font-size-base * 1.7)) !default; // ~24px
// $font-size-h4:            ceil(($font-size-base * 1.25)) !default; // ~18px
// $font-size-h5:            $font-size-base !default;
// $font-size-h6:            ceil(($font-size-base * 0.85)) !default; // ~12px

$line-height-base:        1.6;


// $font-size-h1:						38px;


// Buttons/Forms
// $btn-border-radius-base: 			0;

$input-height-base:					42px;
$input-border-radius:				3px;
$input-border:						#fff;



// Site Variables
$block-padding:		40px !default;











