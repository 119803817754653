.btn-outline {
	@include button-variant($gray-lighter, transparent, $gray-lighter);
	border-width: 2px;

	&:hover {
		color: #b7b7b7;
	}
}

.btn-rounded {
	border-radius: 4em !important;
	font-size: 14px;
	padding: 8px 16px;
}

