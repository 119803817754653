html {
    box-sizing: border-box
}

*,
:after,
:before {
    box-sizing: inherit;
    outline: 0
}

body {
	background-color: #f5f6f8;
	color: #414141;
	
	position: relative;
	margin: 0;
	min-height: 100%;
}

.table {
	&>thead {
		&>tr {
			&>td, &>th {
				border: none;
			}
			&>th {
				font-size: 18px;
			}
		}
	}

	&>tbody {
		&>tr {
			&>td, &>th {
				border: none;
			}
		}
	}
}

.table-striped>tbody>tr:nth-of-type(odd) {
	background-color: #f5f6f8;
}



.vam {
	display: inline-block;
	vertical-align: middle;
}

.text-success {
	color: $brand-success;
}
.text-danger {
	color: $brand-danger;
}


.mt-0  { margin-top: 0 !important; }
.mt-10 { margin-top: 10px !important; }
.mt-20 { margin-top: 20px !important; }
.mt-30 { margin-top: 30px !important; }
.mt-40 { margin-top: 40px !important; }
.mt-60 { margin-top: 60px !important; }
.mt-80 { margin-top: 80px !important; }

.mb-0  { margin-bottom: 0 !important; }
.mb-10 { margin-bottom: 10px !important; }
.mb-20 { margin-bottom: 20px !important; }
.mb-30 { margin-bottom: 30px !important; }
.mb-40 { margin-bottom: 40px !important; }
.mb-60 { margin-bottom: 60px !important; }
.mb-80 { margin-bottom: 80px !important; }

.pt-0  { padding-top: 0 !important; }
.pt-10 { padding-top: 10px !important; }
.pt-20 { padding-top: 20px !important; }
.pt-30 { padding-top: 30px !important; }
.pt-40 { padding-top: 40px !important; }
.pt-60 { padding-top: 60px !important; }
.pt-80 { padding-top: 80px !important; }

.pb-0  { padding-bottom: 0 !important; }
.pb-10 { padding-bottom: 10px !important; }
.pb-20 { padding-bottom: 20px !important; }
.pb-30 { padding-bottom: 30px !important; }
.pb-40 { padding-bottom: 40px !important; }
.pb-60 { padding-bottom: 60px !important; }
.pb-80 { padding-bottom: 80px !important; }


